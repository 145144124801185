import styles from './App.module.css';
import { useEffect, useState } from 'react';
import Vimeo from '@u-wave/react-vimeo';

export default function Home() {
  const [shown, setShown] = useState(1)

  useEffect(() => {
    const interval = setInterval(() => {
      setShown(shown => {
        if (shown === 8) {
          return 1;
        }

        return shown + 1;
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.videoViewer}>
        <div className={styles.video}>
          <Vimeo
            transparent={false}
            video="https://player.vimeo.com/video/767137301?h=4f9a5737e0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            autoplay
            showByline={false}
            showTitle={false}
          />
        </div>
      </div>
      {/*<div className={styles.dancer}>*/}
      {/*  <div className={styles.dancerPose1} style={{*/}
      {/*    backgroundImage: "url('/Layer3.png')",*/}
      {/*    opacity: shown === 5 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*  <div className={styles.dancerPose2} style={{*/}
      {/*    backgroundImage: "url('/Layer4.png')",*/}
      {/*    opacity: shown === 7 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*  <div className={styles.dancerPose3} style={{*/}
      {/*    backgroundImage: "url('/Layer5.png')",*/}
      {/*    opacity: shown === 1 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*  <div className={styles.dancerPose4} style={{*/}
      {/*    backgroundImage: "url('/Layer6.png')",*/}
      {/*    opacity: shown === 3 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.dancer}>*/}
      {/*  <div className={styles.dancerPose1} style={{*/}
      {/*    backgroundImage: "url('/Layer5.png')",*/}
      {/*    opacity: shown === 2 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*  <div className={styles.dancerPose2} style={{*/}
      {/*    backgroundImage: "url('/Layer6.png')",*/}
      {/*    opacity: shown === 6 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*  <div className={styles.dancerPose3} style={{*/}
      {/*    backgroundImage: "url('/Layer3.png')",*/}
      {/*    opacity: shown === 4 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*  <div className={styles.dancerPose4} style={{*/}
      {/*    backgroundImage: "url('/Layer4.png')",*/}
      {/*    opacity: shown === 8 ? 1 : 0 }}*/}
      {/*  />*/}
      {/*</div>*/}
    </div>
  )
}
